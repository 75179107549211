import React, { Component, useEffect, useState, useRef }  from 'react';
import './App.css';
import 'animate.css';
import axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


const serverAddress = 'https://api.lewis-le.com/ctc';
//const synth = window.speechSynthesis;
//const voices = synth.getVoices();



function App() {
	
	const [typing, setTyping] = useState('');
	const [res, setRes] = useState('');
	const [lines, setLines] = useState(['','','']);
	const [line1, setLine1] = useState('');
	const [array_url, setArrayUrl] = useState();
	const typing_ref = useRef();
	const line1_ref = useRef();
	const {
		transcript,
		interimTranscript,
		finalTranscript,
		resetTranscript,
		listening,
	} = useSpeechRecognition();
	
	useEffect(() => {
	   if (finalTranscript !== '') {
		 console.log('Got final result:', finalTranscript);
		 SpeechRecognition.stopListening();
		 handle_chat(finalTranscript);
	   }
	 }, [interimTranscript, finalTranscript]);
	
	useEffect(() => {
		document.addEventListener("keydown", (event) => {
			if (event.defaultPrevented) {
				return; // Do nothing if the event was already processed
			}
			else if (event.key === 'Backspace' && event.ctrlKey) {	//xóa toàn bộ typing (ctrl + backspace)
				setTyping((typing) => typing.slice(0));
			}
			else if (event.key === 'Backspace') {	//nhấn backspace
				setTyping((typing) => typing.slice(0, -1));
			}
			else if (event.key === 'Enter') {	//nhấn enter => submit chat
				SpeechRecognition.stopListening();
				handle_chat(typing_ref.current.textContent);
			}
			else if (event.key !== 'Backspace' && event.key !== 'Enter' && !event.ctrlKey) {
				setTyping((typing) => event.key.length===1 ? typing+event.key : typing );	//chỉ nhận phím có keyname 1 ký tự
				//console.log(event.key.length)
				//console.log(typing.length)
			}
			event.preventDefault();
		}, true);
	}, []);
	
	useEffect(() => {
		let synth = window.speechSynthesis;
		let voices = synth.getVoices();
		let utterance = new SpeechSynthesisUtterance();
		utterance.volume = 1; // From 0 to 1
		utterance.rate = 1; // From 0.1 to 10
		utterance.pitch = 2; // From 0 to 2
		utterance.text = res.replace(/(https?:\/\/[^\s]+)/g,'');
		utterance.voice = voices[1];
		speechSynthesis.speak(utterance);
		if (/\s/g.test(res)) {	//check if include white space => can split()
			res.split(" ").map((line, index) => {
				line = urlify(line);
				setTimeout(() => {
					if (index%7===0 || line.includes("\n")) setLine1('');
					setLine1((line1) => {
						return line1+' '+line;
					});
				}, line.includes(".")||line.includes("\n")||line.includes(",") ? index*340 : index*340);
				// Math.floor(Math.random() * 476) + 470)
			});
		} else if (!/\s/g.test(res)) {	//nếu chỉ có 1 word
			setLine1(res);
		}
		utterance.onend = (event) => {
		  console.log(`Utterance has finished being spoken after ${event.elapsedTime} seconds.`);
		  listenContinuously();
		}
	}, [res]);
	
	function handle_chat(prompt) {
		console.log('Searching . . .');
		//setRes('Searching . . .');
		setTyping('');
		setLine1('');
		setArrayUrl();
		resetTranscript();
		speechSynthesis.cancel();
		axios.post(`${serverAddress}/chat`, {
			prompt: prompt,
		  })
		  .then(function (response) {
			console.log(response);
			setRes(response.data);
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	}
	
	function urlify(text) {
	  var urlRegex = /(https?:\/\/[^\s]+)/g;
	  return text.replace(urlRegex, function(url) {
		//console.log(url);
		setTimeout(() => {
			setArrayUrl(url);
		}, text.length*2);
		return '';
	  });
	}
	
	if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
	   return null;
	}
	if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
	   console.log('Your browser does not support speech recognition software! Try Chrome desktop, maybe?');
	}
	const listenContinuously = () => {
	   SpeechRecognition.startListening({
		 continuous: true,
		 language: 'en-US',
	   });
	 };
	
  return (
    <div className="w-full h-screen flex flex-col justify-bettween bg-emerald-500/80 overflow-hidden">
      <div className='w-full h-1/6'>
		<div className='w-fit h-fit flex gap-3 p-1 bg-white/10 rounded-lg mx-auto'>
			 <button className='text-white' type="button" onClick={resetTranscript}>Reset</button>
			 <button className='text-white' type="button" onClick={listenContinuously}>Listen</button>
			 <button className='text-white' type="button" onClick={SpeechRecognition.stopListening}>Stop</button>
		 </div>
	  </div>
	  
	  <div className='lg:w-8/12 w-10/12 h-5/6 mx-auto'>
		<p ref={line1_ref} className='lg:text-8xl text-5xl text-amber-100 uppercase text-center tracking-wider leading-loose p-exthin'>{line1}</p>
		{
			array_url ? <img src={array_url} className='lg:w-8/12 w-full object-cover mx-auto mt-5' /> : null
		}
	  </div>
	  
	  <div className='w-full h-1/6'>
		<p ref={typing_ref} className='w-fit bg-white/10 py-2 px-5 rounded-lg text-base text-white text-center mx-auto transition-all'>{transcript}</p>
		<p ref={typing_ref} className='w-fit bg-white/20 py-2 px-5 mt-1 rounded-lg text-base text-white text-center mx-auto transition-all' contentEditable={true}>{typing}</p>
		<div className='w-full h-4/6 flex gap-5 p-3'>
		</div>
	  </div>
    </div>
  );
}

export default App;
